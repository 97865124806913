import React from "react"
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Course, SanityKeyed, SanityBlock } from '../../../schema'
import { WithData } from '@utils'
import { BlockRenderer, Grid, BackUp, BackHome, GalleryPiece } from '../'
import { H2, H1 } from '../BlockRenderer'



interface CourseProps {
  data: CourseData
}

interface CourseData {
  sanityCourse: CourseWithRaws
}

interface CourseWithRaws extends Course {
  _rawTitle: Array<SanityKeyed<SanityBlock>>
  _rawOverview: Array<SanityKeyed<SanityBlock>>
}


const CourseTemplate = ({
  data: {
    sanityCourse,
  }
}: CourseProps): React.ReactElement => {

  const meta = [
    { property: "og:title", content: sanityCourse.title },
    { name: "twitter:title", content: sanityCourse.title },
    { name: "description", content: sanityCourse.shareDescription },
    { name: "twitter:description", content: sanityCourse.shareDescription },
    { property: "og:description", content: sanityCourse.shareDescription },    
    { property: "og:image", content:  sanityCourse.shareImage?.asset.url },
    { name: "twitter:image", content: sanityCourse.shareImage?.asset.url },
  ]

  return (
    <>
      <Helmet {...{meta}} />
      <Wrapper>
        <BackHome />
          
        <Description>
          <Section>
            <BlockRenderer>{sanityCourse._rawTitle}</BlockRenderer>
          </Section>
    
          <Section>
            <H2>Overview</H2>
            <BlockRenderer>{sanityCourse._rawOverview}</BlockRenderer>
          </Section>
          
          {(sanityCourse.guests ?? []).length > 0 && (
            <Section>
              <H2>Guest Lecturers</H2>
              <Lecturers>
                {sanityCourse.guests?.map((guest, i) => {
                  return !!guest.link
                    ? <><a key={guest._key} href={guest.link} target="_blank" >{guest.name}</a>{i < sanityCourse.guests!.length - 1 && (", ")}</>
                    : <><span key={guest._key}>{guest.name}</span>{i < sanityCourse.guests!.length - 1 && (", ")}</>
                })}
              </Lecturers>
            </Section>
          )}
        </Description>
        
        {sanityCourse.galleries?.map(gallery => (
          <Section key={gallery._key}>
            <H2>{gallery.title}</H2>
            <Grid>
              {gallery.projects?.map(project => (
                <GalleryPiece {...project} key={project._key} />
              ))}
            </Grid>
          </Section>
        ))}
        
        <BackUp />
      </Wrapper>
    </>
  )
}


const Wrapper = styled.main``


const Description = styled.article`
  @media only screen and (min-width: 744px) {
    width: 60%;
  }
`

const Section = styled.section`
  padding-bottom: 75px;
`


const Lecturers = styled(props => <H1 {...props} />)`
  color: rgba(66, 181, 246, 1);
  a {
    text-decoration: none;
    text-decoration-color: rgba(66, 181, 246, 0.5);
  }
`


const mapDataToProps = ({ sanityCourse }: CourseData) => ({ ...sanityCourse })


export const query = graphql`
  query course($slug: String!) {
    sanityCourse(slug: {current: {eq: $slug}}) {
      _rawTitle
      shareDescription
      shareImage {
        asset {
          url
        }
      }
      slug {
        current
      }
      _rawOverview
      guests {
        _key
        name
        link
      }
      galleries {
        _key
        title
        projects {
          _key
          student
          studentLink
          mediaTypeIsImage
          image {
            asset {
              url
            }
          }
          video {
            asset {
              url
            }
          }
        }
      }
    }
  }
`

export default WithData(mapDataToProps, CourseTemplate)